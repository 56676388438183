<template>
    <div class="main">
        <el-table :data="mainData.Data" style="width: 100%" height="450">
            <el-table-column prop="wishType" label="测评类型">
                <template slot-scope="scope">
                    <el-tag class="item" type="success">{{ typeList[scope.row.TestType]}}</el-tag>
                    <!-- <div>{{ scope.row.WishType }}</div> -->
                </template>
            </el-table-column>
            <el-table-column prop="CreateTime" label="完成时间">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="lookTestTable(scope.row)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]"
                :total="mainData.Total" @size-change="handleSize" @current-change="handlePage"></el-pagination>
        </div>
    </div>
</template>
<script>
import API from '@/api/config';
import { setStore } from "@/utils/utils";
export default {
    data() {
        return {
            mainData: {},
            query: {
                PageIndex: 1,
                PageSize: 10,
            },
            studentInfo: '',
            totalData: [],

            typeList:{
                1: 'MBTI',
                2: '霍兰德',
                3: '中学生人格测评',
                4: '新高考选科测评',
                5: '升学路径测评',
                6: '专业定位综合测评'
            }
        }
    },
    mounted() {
        this.initialize()
    },
    methods: {

        //查看测评表
        lookTestTable(item) {
            if(item.TestType===6){
                setStore('AllroundResultData',item);
                let routeUrl = this.$router.resolve({
                    path: "/allround-result",
                });
                window.open(routeUrl.href, "_blank");
            }
            else{
                if (item.TestType === 5) {
                    this.$ls.set("test-enterPath-answerList", JSON.parse(item.AnswerList));
                }
                else if (item.TestType === 4) {
                    this.$ls.set('test-newExamChooseAnswer', item.AnswerList)
                    item.AttachmentList.forEach(item => {
                        if (item.ParameterName === "ProvinceId") {
                            this.$ls.set('test-newExamChoose', item.ParameterValue)
                        }
                        if (item.ParameterName === "SubjectRanking") {
                            this.$ls.set('test-subjectSort', JSON.parse(item.ParameterValue))
                        }
                    })
                }
                else {
                    this.$ls.set("testAnswer", item.AnswerList);
                }
                let routeUrl = this.$router.resolve({
                    path: "/test-result",
                    query: {
                        testType: item.TestType,
                    },
                });
                window.open(routeUrl.href, "_blank");
            }
        },
        // 分页
        handlePage(val) {
            this.query.PageIndex = val
            this.getList()
        },
        handleSize(val) {
            this.query.PageIndex = 1
            this.query.PageSize = val
            this.getList()
        },
        initialize() {
            this.getList()
        },

        // wish list
        getList() {
            API.User.TestList(this.query).then(res => {
                this.mainData = res.data
            })
        }
    }
}
</script>
<style lang="less" scoped>
.main {
    padding: 0 40px;

    .list {
        margin-bottom: 20px;
        display: flex;
        color: #555;
        font-size: 15px;

        .item {
            margin-right: 15px;
        }
    }

    .pagination {
        margin-top: 10px;
    }

}

.color-red {
    color: #f56c6c;
}
</style>
